<template>
  <b-card
    :img-src="require('@/assets/images/banner/banner-12.jpg')"
    img-alt="Profile Cover Photo"
    img-top
    class="card-profile"
  >
    <div class="profile-image-wrapper">
      <div class="profile-image p-0">
        <b-avatar
          size="114"
          variant="light"
          :src="require('@/assets/images/portrait/small/avatar-s-9.jpg')"
        />
      </div>
    </div>
    <h3>ABC CONSULTING</h3>
    <h6 class="text-muted">
      Paris
    </h6>
    <a v-bind:href="'https://dolibarr.cloud-inspire.io/'" target="_blank">
      <b-badge
        class="profile-badge"
        variant="light-primary"
      >
        ACCESS CRM & ERP
      </b-badge>
    </a>
    <hr class="mb-2">

    <!-- follower projects rank -->
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h6 class="text-muted font-weight-bolder">
          Turnover
        </h6>
        <h3 class="mb-0">
          50.6k
        </h3>
      </div>
      <div>
        <h6 class="text-muted font-weight-bolder">
          Projects
        </h6>
        <h3 class="mb-0">
          5
        </h3>
      </div>
      <div>
        <h6 class="text-muted font-weight-bolder">
          Customers
        </h6>
        <h3 class="mb-0">
          23
        </h3>
      </div>
    </div>
    <!--/ follower projects rank -->
  </b-card>
</template>

<script>
import { BCard, BAvatar, BBadge } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BBadge,
    BAvatar,
  },
}
</script>
