<template>
<div>
  <b-row class="match-height">

    <b-col
      lg="4"
      md="6"
    >
      <card-org-1 />
    </b-col>

    <b-col
      lg="4"
      md="6"
    >
      <card-org-2 />
    </b-col>
    
  </b-row>
  <b-row>
    <b-col
      lg="12"
      md="3"
    >
      <free-trial />
    </b-col>
  </b-row>
</div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import CardAdvanceProfile from './CardAdvanceProfile.vue'
import CardOrg1 from './CardOrg1.vue'
import CardOrg2 from './CardOrg2.vue'


import FreeTrial from './FreeTrial.vue'


export default {
  components: {
    BRow,
    BCol,

    CardAdvanceProfile,
    CardOrg1,
    CardOrg2,
    FreeTrial,

  },
}
</script>
